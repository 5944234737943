import React, {useEffect, useState} from 'react';
import moment from "moment";

const Timer=(props)=> {

    const [time, setTime]=useState({
        hour: '00',
        minute: '00'
    });
    let currentTime = moment().format('HH:mm');

    useEffect(() => {
        (async ()=> {
            if (props.status==='В работе') {
                let oldTime = moment(props.startTime.replace(/,/i, ''), 'DD.MM.YYYY HH:mm').format('DD/MM/YYYY HH:mm');
                let currentTime = moment().format('DD/MM/YYYY HH:mm');
                // console.log(currentTime, oldTime)
                let start = moment(currentTime, 'DD/MM/YYYY HH:mm');
                let end   = moment(oldTime, 'DD/MM/YYYY HH:mm');

                let h = end.diff(start, 'hours');
                let m = end.diff(start, 'minutes') - (60 * h);

                let hh = h.toString().replace(/-/g,'');
                let mm = m.toString().replace(/-/g,'');
                setTime({
                    hour: hh.length<2 ? '0'+hh : hh,
                    minute: mm.length<2 ? '0'+mm : mm
                })
                // console.log(`${hh}:${mm}`);
            }
        })()
    },[currentTime, props.startTime, props.status])

    return (
        <span className='text-order'>{time.hour}:{time.minute}</span>
    )
}


export default Timer;
