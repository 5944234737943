import React from 'react';
import Login from "./components/Login";
import Home from "./components/Home";
import OrderDetail from "./components/data-components/OrderDetail";
import OrderForm from "./components/data-components/OrderForm";
import NoMatch from "./components/404Page";
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import Notification from "./components/common/Notification";
import {connect, useSelector} from "react-redux";
import Loader from "./components/common/Loader";
import UndeliveredEquipment from "./components/UndeliveredEquipment";
import UndeliveredOrderDetail from "./components/data-components/UndeliveredOrderDetail";
import TemporaryEquipment from "./components/TemporaryEquipment";

function App(props) {
    const isLoggedIn = props.userHash;
    const loading = useSelector(state=>state.loader.loading)
      return (

          <>
              {loading && <Loader/>}

              <BrowserRouter>
                  <div className='cover_container mx-auto height'>
                      {props.notification && <Notification text={props.notification}/>}
                      {isLoggedIn === null ? (
                          <Switch>
                              <Route path="/login" exact component={Login} />
                              <Redirect to="/login" />
                          </Switch>
                      ) : (
                          <Switch>
                              <Route path="/" exact component={Home} />
                              <Route path="/temporary-equipment" exact component={TemporaryEquipment} />
                              <Route path="/undelivered-equipment" exact component={UndeliveredEquipment} />
                              <Route path="/undelivered-equipment/:id" exact component={UndeliveredOrderDetail} />
                              <Route path="/order-detail/:id" exact component={OrderDetail} />
                              <Route path="/order-form/:id" exact component={OrderForm} />
                              <Route path="*" component={NoMatch} />
                          </Switch>
                      )}
                  </div>

              </BrowserRouter>

          </>
      );
}

const mapStateToProps = state => {
    return {
        notification: state.notification.message,
        userHash: state.auth.userHash,
    }
}
export default connect(mapStateToProps, null)(App);

// export default App;
